@import 'reset.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import 'mixins.scss';

html,
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #EDEEF0;
  * {
    font-family: 'Montserrat', sans-serif;
    outline: none;
  }
}
a {
  outline: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -ms-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  @include transition (all 0.2s ease-out);
  &:hover {text-decoration: none;}
}
@include autofill;

.hidden {display: none;}

.wrap-text {
  display: block;
  @include mobile-768 {
    display: initial;
  }
}

.section--title{
  color: #000000;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  margin-bottom: 40px;
  @include mobile-768 {
    margin-bottom: 20px;
  }
}

.rayen--button{
  float: left;
	min-width: 150px;
	max-width: 250px;
	display: block;
	margin: 1em;
	padding: 1em 2em;
  border: none;
	background: none;
	color: inherit;
	position: relative;
  z-index: 1;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0;
  width: 100px;
  border: 1px solid white;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  &:focus{
    outline: none;
  }
  &:hover{
    &:before{
      -webkit-transform: translate3d(0, 0, 0);
	    transform: translate3d(0, 0, 0);
    }
    > span {
      -webkit-transform: translate3d(0, 100%, 0);
	    transform: translate3d(0, 100%, 0);
    }
  }
  > span {
    display: block;
    color: white;
    // vertical-align: middle;
    font-size: 16px;
    font-weight: 500;
  }
  &:before {
    content: attr(data-text);
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
  &:before, > span {
    padding: 10px;
    -webkit-transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }
}


// Includes
@import 'menu.scss';


.overlay{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
}

.container{
  max-width: 1300px;
  margin: auto;
  overflow: hidden;
  padding: 0;
  @media screen and (max-width: 1350px){
    max-width: 95%;
  }
}

.header--section{
  height: 800px;
  position: relative;
  @include desk-1024 {
    height: 350px;
  }
  &.video{
    background-color: black;
    height: calc(100vh - 90px);
    @include mobile-740 {
      height: 400px;
    }
  }
  video {
    width: 100%;
    height: 100%
    // aspect-ratio: 16/9;
  }
  .overlay{
    z-index: 2;
  }
  .header--swiper{
    height: 100%;
    &:hover{
      .swiper-button-prev, .swiper-button-next{
        opacity: 1;
      }
    }
    .swiper-slide{
      height: 100%;
      background-color: black;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      .text--container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        z-index: 3;
        .top--title{
          color: white;
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 0;
        }
        .title{
          color: white;
          font-size: 46px;
          line-height: 50px;
          font-weight: 500;
          margin-bottom: 0;
        }
        .divider{
          width: 250px;
          height: 2px;
          background-color: white;
          margin: 18px 0;
        }
        .subtitle{
          color: white;
          font-size: 15px;
          line-height: 20px;
          margin-bottom: 0;
        }
        .rayen--button{
          margin-top: 30px;
        }
      }
    }
  }
  .swiper-button-prev, .swiper-button-next{
    color: white;
    opacity: 0;
    @include transition (opacity 0.2s ease-out);
    @include mobile-740 {
      display: none;
    }
  }
  .swiper-button-prev{
    left: 30px;
  }
  .swiper-button-next{
    right: 30px;
  }
  .swiper-pagination-bullets{
    bottom: 30px;
    .swiper-pagination-bullet{
      width: 15px;
      height: 15px;
      background: #D8DADE;
      &.swiper-pagination-bullet-active{
        background: white;
      }
    }
  }
}


.obras--section{
  padding: 40px 0;
  background-color: white;
  &.grey{
    background-color: #E5E5E5;
  }
  &.four-sizes{
    .obras--list{
      .list--item{
        width: calc((100% / 4) - 20px); 
        @media screen and (max-width: 900px){
          width: calc((100% / 2) - 20px);
        }
        @media screen and (max-width: 700px){
          width: 100%;
        }
        .item--image{
          aspect-ratio: 1/1;
          @media screen and (max-width: 700px){
            aspect-ratio: 2/1;
          }
        }
        .item--bottom{
          padding: 20px;
          .item--title{
            text-align: center;
          }
          .item--text{
            text-align: center; 
          }
        }
      }
    }  
  }
  .obras--list{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: wrap;
    margin-top: 20px;
    gap: 20px;
    .list--item{
      width: calc((100% / 3) - 20px);
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 900px){
        width: calc((100% / 2) - 20px);
      }
      @media screen and (max-width: 700px){
        width: 100%;
      }
      .item--image{
        width: 100%;
        aspect-ratio: 1.5/1;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
      .item--bottom{
        padding: 20px 0;
        @media screen and (max-width: 900px){
          min-height: 155px;
        }
        @media screen and (max-width: 700px){
          min-height: auto;
        }
        .item--title{
          font-size: 15px;
          line-height: 20px;
          font-weight: 600;
          color: #E0040B;
          margin-bottom: 2px;
        }
        .item--subtitle{
          font-size: 10px;
          line-height: 15px;
          color: black;
        }
        .item--text{
          min-height: 85px;
          font-size: 12px;
          line-height: 17px;
          color: black;
          margin-top: 10px;
          @media screen and (max-width: 700px){
            min-height: auto;
          }
        }
        .button{
          color: $primaryColor;
          border: 2px solid $primaryColor;
          border-radius: 100px;
          font-size: 15px;
          line-height: 20px;
          padding: 6px 16px;
          text-decoration: none;
          margin-top: 15px;
          transition: all .2s ease-in-out;
          display: inline-block;
          &:hover{
            background-color: $primaryColor;
            color: white;
          }
        }
      }
    }
  }
}


.banner--section{
  padding: 100px 0;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 700px){
    padding: 80px 0 240px;
  }
  &.with-bg1{
    background-image: url('../img/banner1.jpg');
    @media screen and (max-width: 700px){
      background-image: url('../img/bn1.jpg');
    }
    .container{
      justify-content: flex-start;
    }
    .text--container{
      max-width: 600px;
    }
  }
  &.with-bg2{
    background-image: url('../img/banner2.jpg');
    @media screen and (max-width: 700px){
      background-image: url('../img/bn2.jpg');
    }
    .container{
      justify-content: flex-start;
    }
    .text--container{
      max-width: 500px;
      .text--title{
        margin-bottom: 0;
      }
      .subtext--title{
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  &.reverse{
    .container{
      flex-flow: row-reverse;
    }
    .text--container{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media screen and (max-width: 700px){
        align-items: center;
      }
      .text{
        // text-align: right;
      }
      .text--title{
        // text-align: right;
      }
    }
  }
  .container{
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 900px){
      flex-direction: column;
      align-items: center;
    }
  }
  .text--container{
    max-width: 400px;
    @media screen and (max-width: 1024px){
      margin-right: 0;
    }
    @media screen and (max-width: 900px){
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .banner--logo{
      width: 200px;
      display: inline-block;
      margin-bottom: 10px;
    }
    .subtext--title{
      color: black;
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      @media screen and (max-width: 900px){
        text-align: center;
      }
      @media screen and (max-width: 700px){
        max-width: 80%;
      }
    }
    .text--title{
      color: black;
      font-size: 30px;
      font-weight: 600;
      line-height: 34px;
      text-align: left;
      margin-bottom: 10px;
      @media screen and (max-width: 900px){
        text-align: center;
      }
      @media screen and (max-width: 700px){
        max-width: 80%;
      }
      span {
        color: $primaryColor;
        display: block;
      }
    }
    .text{
      color: black;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      @media screen and (max-width: 900px){
        text-align: center;
      }
      @media screen and (max-width: 700px){
        line-height: 28px;
      }
    }
    .button{
      color: $primaryColor;
      border: 1px solid $primaryColor;
      border-radius: 100px;
      font-size: 14px;
      line-height: 19px;
      padding: 8px 16px;
      text-decoration: none;
      margin-top: 30px;
      display: inline-block;
      transition: all .2s ease-in-out;
      @media screen and (max-width: 700px){
        font-size: 20px;
        line-height: 26px;
        padding: 8px 30px;
      }
      &:hover{
        background-color: $primaryColor;
        color: white;
      }
    }
  }
  .image--container{
    img {
      width: 100%;
      max-width: 400px;
    }
  }
}

.footer--section{
  background-color: #5C5B5F;
  padding: 40px 0;
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile-740 {
      flex-flow: wrap;
    }
  }
  .image--list{
    display: flex;
    align-items: center;
    @include mobile-740 {
      width: 50%;
    }
    @include mobile-460 {
      width: 100%;
      justify-content: center;
      flex-direction: column;
    }
    &+.image--list{
      @include mobile-460 {
        margin-top: 40px;
        flex-direction: row;
      }
      
    }
    &:nth-child(1){
      .list--item{
        img{
          height: 75px;
        }
      }  
    }
    &:nth-child(2){
      .list--item{
        img {
          height: 25px;
        }
      }  
    }
    .list--item{
      &.text--item{
        border-left: 1px solid white;
        margin-left: 50px;
        @include mobile-740 {
          margin: 25px 0;
          border: 0;
        }
        p{
          color: white;
          font-size: 14px;
          line-height: 20px;
          padding: 0 50px;
          @include mobile-740 {
            text-align: center;
          }
        }
        &.m-0{
          margin-left: 0;
        }
      }
      &+.list--item{
        img {
          margin-left: 35px;
        }
      }
    }
  }
  .footer--text{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-align: right;
    a {
      color: black;
      text-decoration: none;
    }
    @include mobile-992 {
      font-size: 12px;
      line-height: 14px;
    }
    @include mobile-740 {
      margin-top: 20px;
    }
    @include mobile-460 {
      width: 100%;
      text-align: center;
    }
  }
}

.two--section{
  background-color: white;
  padding: 30px 0;
  .content--list{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile-740 {
      flex-direction: column;
    }
    .content--item{
      width: 49%;
      height: 180px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      @include mobile-740 {
        width: 100%;
      }
      &:hover .item--hover{
        opacity: 1;
      }
      &:nth-child(1){
        background-position: right;
      }
      &:nth-child(2){
        background-position: left;
      }
      & + .content--item{
        @include mobile-740 {
          margin-top: 15px;
        }
      }
      .item--hover{
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        @include transition(opacity .2s ease-out);
      }
    }
  }
}

.subscribe--section{
  background-color: #19415C;
  padding: 50px 0;
  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile-740 {
      flex-direction: column;
    }
  }
  .text--content{
    width: 35%;
    display: flex;
    flex-direction: column;
    @include mobile-740 {
      width: 100%;
    }
    .text--title{
      color: white;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 5px;
    }
    .text--subtitle{
      color: white;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .form--content{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
    @include mobile-740 {
      width: 100%;
      margin-top: 40px;
    }
    input { 
      width: calc(100% - 130px);
      border: 0;
      height: 40px;
      padding: 0 10px;
      font-size: 15px;
    }
    .rayen--button{
      min-width: 100px;
      width: 100px;
      // border-radius: 0;
    }
    .submit--message--sub{
      position: absolute;
      color: white;
      bottom: -10px;
      display: none;
    }
  }
}

.text--section{
  padding: 60px 0;
  background-color: white;
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .right--text{
    max-width: 800px;
    margin-right: 20px;
    @media screen and (max-width: 900px){
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .text--title{
      color: #E0040B;
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      text-align: center;
      @media screen and (max-width: 700px){
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
  .left--text{
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 900px){
      max-width: 80%;
    }
    @media screen and (max-width: 700px){
      max-width: 100%;
    }
    .home--text{
      color: black;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 60px;
      text-align: center;
    }
    .text{
      color: black;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 20px;
      text-align: center;
    }
    .button{
      color: black;
      border: 2px solid black;
      border-radius: 100px;
      font-size: 18px;
      line-height: 23px;
      padding: 8px 20px;
      text-decoration: none;
      margin-top: 30px;
      transition: all .2s ease-in-out;
      &:hover{
        background-color: black;
        color: white;
      }
    }
  }
}
.button--list{
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 2px solid black;
  .list--item{
    border: 1px solid black;
    border-radius: 6px;
    background-color: white;
    color: black;
    font-size: 14px;
    line-height: 18px;
    padding: 6px 22px;
    @include transition(all .2s ease-out);
    cursor: pointer;
    &:hover{
      color: white;
      background-color: black;
      a {
        color: white;
      }
    }
    &+.list--item{
      margin-left: 15px;
    }
    a {
      color: black;
    }
  }
}

.bancarios--section{
  background-color: #25B0B9;
  padding: 30px 0;
  .section--title{
    color: white;
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 20px;
  }
  .box--list{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;
    .list--item{
      width: calc(33.33% - 10px);
      height: 223px;
      background-color: white;
      padding: 20px;
      @include mobile-992 {
        width: calc(50% - 10px);
        height: 250px;
        margin-top: 15px;
      }
      @include mobile-460 {
        width: 100%;
      }
      &:nth-child(n + 4){
        margin-top: 15px;
      }
      .item--title{
        color: #25B0B9;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px;
      }
      .item--text{
        color: black;
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

.phones--section{
  padding: 40px 0;
  background-color: #EDEEF0;
  .section--title{
    color: #25B0B9;
    font-size: 30px;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 20px;
  }
  .box--list{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-flow: wrap;
    @include mobile-992 {
      justify-content: center;
    }
    .list--item{
      width: calc(20% - 10px);
      background-color: white;
      padding: 20px;
      @include mobile-992 {
        width: calc(33.33% - 10px);
        margin: 20px 5px 0;
      }
      @include mobile-460 {
        width: 100%;
      }
      .item--title{
        color: #25B0B9;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 15px;
        @include mobile-460 {
          text-align: center;
        }
      }
      .item--text{
        color: black;
        font-size: 15px;
        line-height: 20px;
        @include mobile-460 {
          text-align: center;
        }
        a {
          color: black;
        }
      }
    }
  }
}

.agencias--section{
  .agencias--top{
    background-color: white;
    padding: 45px 0;
    .title{
      color: black;
      font-size: 28px;
      line-height: 36px;
    }
    .subtitle{
      color: black;
      font-size: 16px;
      line-height: 22px;
    }
    .form--filter{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;
      .select{
        background: transparent;
        border: 1px solid black;
        font-size: 14px;
        height: 30px;
        padding: 5px;
        width: 250px;
        &+.select{
          margin-left: 20px;
        }
      }
    }
  }
  .agencias--bottom{
    padding: 30px 0;
    .agencias--list{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: wrap;
      .list--item{
        width: calc(33.33% - 10px);
        background: white;
        padding: 20px;
        margin: 5px;
        @include mobile-740 {
          width: calc(50% - 10px);
        }
        @include mobile-460 {
          width: 100%;
          margin: 10px 0;
        }
        .title{
          color: #25B0B9;
          font-size: 23px;
          line-height: 28px;
          @include mobile-992 {
            font-size: 20px;
            line-height: 24px;
          }
        }
        .description{
          color: black;
          font-size: 12px;
          line-height: 14px;
          a {
            color: #25B0B9;
            &:hover{
              color: darken(#25B0B9, 10%)
            }
          }
        }
      }
    }
  }
}

.salida--section{
  padding: 40px 0;
  background-color: white;
  .salida--top{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 2px solid black;
    @include mobile-460 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .salida--left{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      .title{
        color: black;
        font-size: 28px;
        line-height: 34px;
        font-weight: 500;
      }
      .subtitle{
        color: black;
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      .description{
        color: black;
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
      }
      .button--list{
        border-top: 0;
        padding-top: 20px;
      }
    }
    .salida--right{
      display: flex;
      flex-direction: column;
      @include mobile-460 {
        width: 100%;
        margin-top: 30px;
      }
      .right--top{
        color: black;
        font-size: 36px;
        line-height: 36px;
        text-align: right;
        font-weight: 500;
        @include mobile-460 {
          width: 100%;
          text-align: center;
        }
      }
      .right--bottom{
        color: black;
        font-size: 13px;
        line-height: 13px;
        font-weight: 500;
        text-align: right;
        @include mobile-460 {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .salida--bottom{
    padding-top: 30px;
    color: black;
    font-size: 14px;
    line-height: 18px;
  }
}

.modal--section{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  display: none;
  .modal--content{
    width: 600px;
    height: 600px;
    background-color: white;
    position: relative;
    @media screen and (max-width: 600px){
      width: 200px;
      height: 200px;
    }
    .modal--close{
      position: absolute;
      top: -5px;
      right: -5px;
    }
    img { 
      width: 100%;
    }
  }
}
