/*-- Media Queries mixin --*/
@mixin mobile-320 {
  @media (max-width: 320px) {
    @content;
  }
}
@mixin mobile-380 {
  @media (max-width: 380px) {
    @content;
  }
}
@mixin mobile-460 {
  @media (max-width: 460px) {
    @content;
  }
}
@mixin mobile-740 {
  @media (max-width: 740px) {
    @content;
  }
}
@mixin mobile-768 {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin mobile-992 {
  @media (max-width: 992px) {
    @content;
  }
}
@mixin mobile-1023 {
  @media (max-width: 1023px) {
    @content;
  }
}
@mixin desk-1024 {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desk-1200 {
  @media (max-width: 1200px) {
    @content;
  }
}
@mixin desk-1366 {
  @media (max-width: 1366px) {
    @content;
  }
}
@mixin desk-1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
}

@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
  -ms-transform: $transform;
}

@mixin box-shadow($shadow) {
  -ms-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin bgCover ($repeat:no-repeat, $size: cover, $position: center) {
  background-repeat: $repeat;
  background-size: $size;
  background-position: $position;
}

@mixin autofill {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 30px white inset;
    background-color: transparent;
    background-image: none;
    transition: background-color 5000s ease-in-out 0s;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    color: rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 0 30px white inset;
    background-color: transparent;
    background-image: none;
    transition: background-color 5000s ease-in-out 0s;
  }
}
