@import 'mixins.scss';

$primaryColor: #E0040B;
$primaryColorDark: darken($primaryColor, 10%);
$secondaryColor: #E0040B;
$secondaryColorDark: darken($secondaryColor, 10%);
$colorBlack: #000000;
$colorWhite: #fff;

.menu--desktop {
  width: 100%;
  margin: 0 auto;
  background-color: $colorWhite;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  @include transition(all 0.2s ease-in);
  &.new--menu{
    .brand-link {
      display: flex;
      align-items: center;
      justify-content: center;
      .brand{
        position: static;
        left: 0;
        top: 0;
        @include transform(translateY(0%));
      }
    }
  }
  .container {
    height: 90px;
    @include mobile-740 {
      height: 75px;
    }
    .row {height: 100%;}
  }
  .brand-link {
    width: auto;
    height: 90px;
    align-items: center;
    display: flex;
    position: relative;
    @include mobile-740 {
      width: 170px;
      height: 75px;
    }
    .brand, .brand-white {
      width: auto;
      max-width: 160px;
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 0;
      @include transform(translateY(-50%));
      @include transition(all 0.2s ease-in);
      @include mobile-460 {
        max-width: 130px;
      }
    }
    .brand {opacity: 1;}
    .brand-white {opacity: 0;}
  }

  .menu--list {
    width: 100%;
    height: 90px;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @include mobile-992 {
      display: none;
    }
    .item--red{
      color: #E0040B;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    .item--black{
      color: #000;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        margin-right: 20px;
      }
    }
    .menu--item {
      height: 100%;
      border-bottom: 8px solid transparent;
      a {
        height: 100%;
        align-items: center;
        color: $colorBlack;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        padding: 0 25px;
        i {
          font-size: 14px;
          margin-left: 10px;
          position: relative;
          top: 1px;
        }
        &:hover, &.active {
          color: $secondaryColor;
        }
        &.products{
          border-bottom: 8px solid transparent;
        }
      }
      &.try {
        width: 0;
        height: 40px;
        border: none;
        overflow: hidden;
        opacity: 0;
        padding: 0;
        @include transition(all 0.3s 0.2s ease-in);
      }

      .submenu--desktop {
        width: 100%;
        padding: 20px 10%;
        align-items: center;
        background-color: white;
        border-bottom: 1px solid white;
        border-top: 1px solid white;
        display: flex;
        opacity: 0;
        justify-content: space-around;
        visibility: hidden;
        position: fixed;
        left: 0;
        top: 0;
        z-index: -1;
        @include  transform(translate(0, 0));
        @include transition(all 0.2s ease-in-out);
        @include mobile-992 {
          display: none;
        }
        li{
          .link--submenu {
            align-items: center;
            color: $colorWhite !important;
            display: flex;
            flex-flow: column wrap;
            font-size: 19px;
            line-height: 22px;
            font-weight: 700;
            justify-content: center;
            padding: 10px;
            text-align: center;
            &:hover {
              color: #605c8c !important;
            }
            .icon--submenu {
              width: auto;
              max-height: 80px;
              margin-bottom: 10px;
            }
            .text--submenu{
              min-height: 44px;
              color: #6e6e71;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
            }
          }
        }
      }
      &:hover {
        a {
          color: $secondaryColor;
          // font-weight: 700;
          i {
            &:before {
              content: '\f077' !important;
            }
          }
        }
        .submenu--desktop {
          opacity: 1;
          visibility: inherit;
          @include  transform(translate(0, 90px));
        }
      }
      &.products {
        &:hover {
          border-bottom: 8px solid $secondaryColor;
        }
      }
    }
  }

  .sidebar-btn {
    width: 60px;
    height: 60px;
    align-items: center;
    background: transparent;
    border: none;
    box-shadow: none;
    display: none;
    justify-content: center;
    outline: 0;
    line-height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 6000 !important;
    @include border-radius(50%);
    @include transform(translateY(-50%));
    @include transition(all 0.2s ease-out);
    i {
      color: $colorBlack;
      font-size: 30px;
      @include transition(all 0.2s ease-out);
    }
    @include mobile-992 {
      display: block;
    }
  }

  &.sticky {
    background-color: $colorWhite;
    border-bottom: 1px solid #e8e8e8;
    .brand-link {
      .brand {opacity: 0;}
      .brand-white {opacity: 1;}
    }
    .menu--list {
      .menu--item {
        a {color: $primaryColor;}
        &:hover {
          a {color: $secondaryColor;}
        }
        &.try {
          width: auto;
          height: 40px;
          background-color: $secondaryColor;
          opacity: 1;
          a {
            color: $colorWhite;
            font-weight: 700;
          }
          &:hover {background-color: $secondaryColorDark;}
        }
      }
    }
    .sidebar-btn {
      i {
        color: #8a8a8a;
      }
    }
  }

  &.products {
    .brand-link {
      .brand {opacity: 0;}
      .brand-white {opacity: 1;}
    }
    .menu--list {
      .menu--item {
        a {color: #6e6e71;}
        &:hover {
          a {color: $secondaryColor;}
        }
      }
    }
    .sidebar-btn {
      i {
        color: #8a8a8a;
      }
    }
  }
}


.layer-black {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  visibility: hidden;
  @include transition(all 0.4s ease);
  &.active {
    visibility: visible;
  }
  @include mobile-992 {
    display: block;
  }
}

.sidebar-btn-close {
  width: 50px;
  height: 50px;
  background: $colorWhite;
  color: $secondaryColor;
  box-shadow: none;
  font-size: 23px;
  position: absolute;
  top: 50%;
  right: 260px;
  z-index: 9999;
  @include transform(translateY(-50%));
  @include border-radius(50%);
  &:hover, &:focus {
    color: $secondaryColor;
    border: none;
    box-shadow: none;
    outline: none;
  }
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  height: 100vh;
  background: $colorWhite;
  box-sizing: border-box;
  display: none;
  position: fixed;
  right: -320px;
  z-index: 9999999;
  top: 0;
  @include transition(all 0.4s ease);
  &.active {
    right: 0;
  }
  @include mobile-992 {
    display: block;
  }

  .wrapper--sidebar {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .menubar--mobile {
      width: 100%;
      margin: 30px auto;
      li {
        a {
          width: 100%;
          color: $primaryColor;
          display: block;
          padding: 20px;
          text-align: left;
          font-size: 18px;
          border-bottom: 1px solid #dadada;
          i{
            color: #909090;
            border: none;
            position: absolute;
            top: 50%;
            right: 20px;
            @include transition(all 0.2s ease-in-out);
            @include transform(translateY(-50%));
          }
        }
        ul {
          background-color: #eaeaea;
          li {
            a {
              color: #5f5f5f;
              border: none;
              font-size: 16px;
              line-height: 23px;
              padding: 15px;
              padding-left: 35px;
            }
          }
        }
      }
    }

    .button {
      max-width: 170px;
      font-size: 16px;
      margin: 0 auto;
    }
  }
}

#sidebar ul li.active>a, a[aria-expanded="true"] {
  font-weight: 700;
  i{
    color: $primaryColor !important;
    &:before {
      content: '\f077' !important;
    }
  }
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: none;
}

.menu--fix{
  margin-top: 90px;
  @include mobile-740 {
    margin-top: 75px;
  }
}